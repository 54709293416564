<!--
 * @Author: wxy
 * @Date: 2022-08-08 09:42:15
 * @LastEditTime: 2022-10-29 14:28:28
 * @Description: 服务类型详情
-->
<template>
  <div class="serverConsultingDetail">
    <div class="header">
      <ds-header title="服务类型详情" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div class="content">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="所属项目"> {{ form.projectIdList }} </a-form-model-item>
        <a-form-model-item label="服务类型名称"> {{ form.serverTypeName }} </a-form-model-item>
        <a-form-model-item label="服务类型LOGO上传">
          <img :src="form.serverTypeLogo" alt="" class="img" />
        </a-form-model-item>
        <a-form-model-item label="服务图片展示上传">
          <img :src="form.serverTypeImage" alt="" v-if="form.serverTypeImage" class="img" />
          <span v-else>暂无</span>
        </a-form-model-item>
        <a-form-model-item label="服务简介">
          <span v-html="form.serverTypeIntroduce" class="notice"></span>
        </a-form-model-item>
        <a-form-model-item label="是否展示服务供应商">
          {{ form.isShowSupplier === 0 ? "是" : "否" }}
        </a-form-model-item>
        <a-form-model-item label="服务供应商"> {{ form.supplierNames || "暂无" }} </a-form-model-item>
        <a-form-model-item label="服务模式">
          {{ form.serviceModeTypeList.includes(1)?'工单咨询':'' }}
          {{ form.serviceModeTypeList.length>1?',':'' }}
          {{ form.serviceModeTypeList.includes(2)?'电话咨询':'' }}
        </a-form-model-item>
        <a-form-model-item v-if="form.serviceModeTypeList.includes(2)" label="联系电话">
          {{ form.phoneNumber }}
        </a-form-model-item>
        <a-form-model-item v-if="form.serviceModeTypeList.includes(1)" label="咨询受理人">
          {{ form.serviceMode === 0 ? "仅Fellow":form.serviceMode === 1? "Fellow+运营" : ""  }}
        </a-form-model-item>
        <a-form-model-item label="状态"> {{ form.serverTypeStatus === 0 ? "已启用" : "已禁用" }} </a-form-model-item>
        <a-form-model-item label="创建人"> {{ form.createIdShow }} </a-form-model-item>
        <a-form-model-item label="创建时间">{{ form.gmtCreate }}</a-form-model-item>
        <a-form-model-item label="最后修改人"> {{ form.modifiedIdShow || "暂无" }} </a-form-model-item>
        <a-form-model-item label="最后修改时间"> {{ form.gmtModified || "暂无" }} </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
export default {
  name: "serverTypeDetail",
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.getDetailList();
  },
  methods: {
    // 获取详情
    async getDetailList() {
      try {
        const res = await api.serverTypeDetail(this.$route.query.id);
        if (res.code === "200") {
          res.data.projectIdList = [];
          this.form = res.data;
          let temp = "";
          this.form.projectInfo.forEach((item) => {
            temp = temp + "，" + item.projectName;
          });
          this.form.projectIdList = temp.substring(1);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.content {
  width: 70%;
  margin: 10px auto;
}
.img{
  width: 126px;
  height: 126px;
}
::v-deep  table {
     border: none;
     border-collapse: collapse;
}
::v-deep  table td{
     border: 1px solid #ccc;
     padding: 3px 5px;
     min-width: 50px;
     height: 20px;
}
::v-deep  table th {
     border-right: 1px solid #ccc;
     border-bottom: 2px solid #ccc;
     text-align: center;
     background-color: #f1f1f1;
     border: 1px solid #ccc;
     padding: 3px 5px;
     min-width: 50px;
     height: 20px;
}
 
</style>